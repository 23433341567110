const EVENT_TYPES = {
    RESIZE: 'EVENTS_ON_RESIZE',
    RESIZE_STOP: 'EVENTS_ON_RESIZE_STOP',
    MOUSE_MOVE: 'EVENTS_ON_MOUSE_MOVE',
    MOUSE_ENTER: 'EVENTS_ON_MOUSE_ENTER',
    MOUSE_LEAVE: 'EVENTS_ON_MOUSE_LEAVE',
    CLICK: 'EVENTS_ON_CLICK',
    ANIMATION_END: 'ANIMATION_END'
};

const ANIMATION = {
    SPEED_X: 0.12,
    SPEED_Y: 0.4,
    EASE: 'sine.out'
};

const CONTENT_TYPE = {
    list: 'list',
    text: 'text',
    form: 'form'
};

export {EVENT_TYPES, ANIMATION, CONTENT_TYPE};